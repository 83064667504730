import Typography from "typography";

const typography = new Typography({
  title: "FrontCode blog theme",
  baseFontSize: "18px",
  baseLineHeight: 1.666,
  googleFonts: [
    {
      name: "Lato",
      styles: ["300", "400", "700"]
    }
  ],
  bodyFontFamily: ["Lato", "Lucida Grande", "Tahoma", "sans-serif"],
  headerFontFamily: ["Lato", "Lucida Grande", "Tahoma", "sans-serif"]
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
